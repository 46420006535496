<template>
  <FieldLabel
    v-if="label"
    :id="id"
    :label="label"
    :flag="required ? 'Verplicht' : null"
  />
  <div
    class="select-input"
    :class="type"
  >
    <select
      :id="id"
      ref="input"
      :value="modelValue"
      :class="{ 'placeholder-shown': placeholderShown, 'show-valid': showValid }"
      :name="name"
      v-bind="$attrs"
      :disabled="disabled"
      :required="required"
      @input="handleInput"
      @change="handleChange"
    >
      <option
        v-if="placeholder"
        value=""
        disabled
        selected
        hidden
      >
        {{ placeholder }}
      </option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <Icon
      :icon="icon"
      size="sm"
    />
  </div>
  <FieldError :error="error" />
</template>

<script setup>

import { computed, ref, watch } from 'vue';
import {
    useErrorProps,
    useFieldHandlers,
    useInputProps,
    useLabelProps,
} from '../composables/input.js';
import Icon from '../../../abstracts/elements/icon/Icon.vue';
import FieldLabel from '../field/FieldLabel.vue';
import FieldError from '../field/FieldError.vue';

const emit = defineEmits(['update:modelValue', 'update:error', 'input', 'change']);
const input = ref(null);
const props = defineProps({
    ...useInputProps,
    ...useLabelProps,
    ...useErrorProps,
    showValid: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'border-bottom',
        validator(value) {
            return ['border-bottom', 'button'].includes(value);
        },
    },
    placeholder: {
        type: String,
        default: '',
    },
    options: {
        type: Array,
        default: () => [],
    },
    icon: {
        type: String,
        default: 'x-fa-chevron-down',
    },
});

const {
    handleInput,
    handleChange,
} = useFieldHandlers(props, emit);

/* Handle Error */
watch(() => props.error, (value) => {
    if (value) {
        input.value.setCustomValidity(value);
    } else {
        input.value.setCustomValidity('');
    }
});

const placeholderShown = computed(() => !props.modelValue && props.placeholder);

</script>

<style lang="scss" scoped>
@import './style/SelectInput.scss';
</style>
